window.addEventListener("DOMContentLoaded", async () => {
  $(".hamburger").click(() => {
    $("body").addClass("nav-open");
    $(".modal-menu").css("display", "block");
    $(".black-bg").css("display", "block");
  });
  $(".black-bg").click(() => {
    $(".modal-menu").css("display", "none");
    $(".black-bg").css("display", "none");
  });
});
